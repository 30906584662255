exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.screen_3wFQ0kAj {\n  z-index: 1;\n  max-width: 1200px;\n}\n.main_12FP2-AR {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 10px;\n  background: rgba(255, 255, 255, .4);\n}\n.input_Nz1CR2HU {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  margin-right: 10px;\n}\n", "", {"version":3,"sources":["D:/blog/client/src/components/User/AuthVerificate.vue"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,kBAAkB;CACnB;AACD;EACE,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,0BAA0B;MACtB,uBAAuB;UACnB,oBAAoB;EAC5B,cAAc;EACd,oCAAoC;CACrC;AACD;EACE,oBAAoB;MAChB,YAAY;UACR,QAAQ;EAChB,mBAAmB;CACpB","file":"AuthVerificate.vue","sourcesContent":["\n.screen {\n  z-index: 1;\n  max-width: 1200px;\n}\n.main {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 10px;\n  background: rgba(255, 255, 255, .4);\n}\n.input {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"screen": "screen_3wFQ0kAj",
	"main": "main_12FP2-AR",
	"input": "input_Nz1CR2HU"
};