exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.buttonBox_2d-Mr0uM {\r\n  line-height: 50px;\r\n  text-align: right;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/components/User/AuthSignFooter.vue"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,kBAAkB;CACnB","file":"AuthSignFooter.vue","sourcesContent":["\n.buttonBox {\r\n  line-height: 50px;\r\n  text-align: right;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"buttonBox": "buttonBox_2d-Mr0uM"
};