exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.inner_2NP450QK {\r\n  position: relative;\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-orient: horizontal;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-flow: wrap row;\r\n          flex-flow: wrap row;\r\n  padding: 10px;\n}\n.avatar_rL4JBa9F {\r\n  margin: 10px;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/components/User/UserList.vue"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,+BAA+B;EAC/B,8BAA8B;MAC1B,wBAAwB;UACpB,oBAAoB;EAC5B,cAAc;CACf;AACD;EACE,aAAa;CACd","file":"UserList.vue","sourcesContent":["\n.inner {\r\n  position: relative;\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-orient: horizontal;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-flow: wrap row;\r\n          flex-flow: wrap row;\r\n  padding: 10px;\n}\n.avatar {\r\n  margin: 10px;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"inner": "inner_2NP450QK",
	"avatar": "avatar_rL4JBa9F"
};