exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.wrap_nqMpNpxX {\r\n  position: relative;\r\n  margin: 12px 0;\r\n  line-height: 40px;\n}\n.errShow_3ylglHI_ {\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 10px;\r\n  font-size: 1em;\r\n  color: red;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/InputWithTest.vue"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;CACnB;AACD;EACE,mBAAmB;EACnB,OAAO;EACP,UAAU;EACV,YAAY;EACZ,eAAe;EACf,WAAW;CACZ","file":"InputWithTest.vue","sourcesContent":["\n.wrap {\r\n  position: relative;\r\n  margin: 12px 0;\r\n  line-height: 40px;\n}\n.errShow {\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 10px;\r\n  font-size: 1em;\r\n  color: red;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrap": "wrap_nqMpNpxX",
	"errShow": "errShow_3ylglHI_"
};